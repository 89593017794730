import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import RightArrow from '../../images/svg/RightArrowSVG';
import NewsListWrapper from '../../styles/index/NewsListingStyles';

const NewsListing = ({ data }) => {
  const { frontmatter, excerpt } = data;

  const setSlug = type => {
    if (type === 'newsPost') {
      return `/news/${frontmatter.slug}`;
    } else if (type === 'service') {
      return `/services/${frontmatter.slug}`;
    }
  };

  return (
    <NewsListWrapper>
      <Link
        to={setSlug(frontmatter.type)}
        aria-label={`Read News post: ${frontmatter.title}`}
      >
        <h2>
          {frontmatter.title}
        </h2>
        <ul>
          {frontmatter.tags &&
            frontmatter.tags.map((tag, i) => (
              <li className="listingTag" key={i}>
                <h5>{tag}</h5>
              </li>
            ))}
        </ul>
        <h3>
          <Moment date={frontmatter.date} format="YYYY年 MM月DD日" />
        </h3>
        <p>{excerpt}</p>
        <div className="readMore">
          <p>Read More</p>
          <RightArrow />
        </div>
      </Link>
    </NewsListWrapper>
  );
};

NewsListing.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NewsListing;
