import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import NewsListing from '../components/index/NewsListing';
import NewsPageWrapper from '../styles/news/NewsPageStyles';
// import Moment from 'react-moment';
import Layout from '../templates/layout';

const News = ({ path, data }) => {
  const seo = {
    page: 'news',
    title: 'ニュース',
    description:
      // eslint-disable-next-line quotes
      "I like writing about things. Life updates, random epiphanies, cool lessons, etc. Find 'em here!",
    url: 'https://about.9ualia.com/news',
    imgUrl: `${data.pageImg.publicURL}`,
    imgAlt:
      'jdcastro logo, twitter, instagram, facebook, github icons with @jacobdcastro username',
    breadcrumbs: [
      {
        name: 'News',
        path: '/news',
      },
    ],
  };

  return (
    <Layout seo={seo} path={path}>
      <NewsPageWrapper>
        <h1>ニュース</h1>
        <div className="newsPostList">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <NewsListing key={node.id} data={node} />
          ))}
        </div>
      </NewsPageWrapper>
    </Layout>
  );
};

News.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default News;

export const NEWS_PAGE_QUERY = graphql`
  query NEWS_PAGE_QUERY {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "newsPost" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 300)
          html
          timeToRead
          frontmatter {
            date
            title
            slug
            subtitle
            tags
            type
          }
        }
      }
    }

    pageImg: file(relativePath: { eq: "page-meta-img.jpg" }) {
      publicURL # used for SEO
    }
  }
`;
